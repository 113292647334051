<template>
  <div class="blue_bg pb-5">
    <div class="container">
      <Registration></Registration>
    </div>
  </div>
</template>

<script>
import Registration from "../../components/Auth/Registration";

export default {
  name: "RegistrationView",
  components: { Registration },
  data() {
    return {};
  },
  mounted() {
    const vm = this;
  },

  computed: {},
  methods: {}
};
</script>

<style scoped>
</style>
